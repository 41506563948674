export const addresses = {
    "cZRX": "0xAf45ae737514C8427D373D50Cd979a242eC59e5a",
    "cWBTC legacy": "0xa1fAA15655B0e7b6B6470ED3d096390e6aD93Abb",
    "cWBTC": "0xbf3f6592c1f523eee8e6a78a50264531480bbf7e",
    "cUSDT": "0x3f0A0EA2f86baE6362CF9799B523BA06647Da018",
    "cUSDC": "0x4a92E71227D294F041BD82dd8f78591B75140d63",
    "cETH": "0x41B5844f4680a8C38fBb695b7F9CFd1F64474a72",
    "cBAT": "0x4a77fAeE9650b09849Ff459eA1476eaB01606C7a",
    "cDAI": "0xF0d0EB522cfa50B716B3b1604C4F0fA6f04376AD", 
    "Comptroller": "0x5eAe89DC1C671724A672ff0630122ee834098657",
    "COMP": "0x61460874a7196d6a22d1ee4922473664b3e95270",

    "compUserInfoAddress": "0x2df603eef6ff4a02c527164cbcdae77fa393dc3c",
    "bComptrollerAddress": "0x777f6369cc1cf921905c8899e127391d6ee5f009",
    "registryAddress": "0x0056186fbfcb8cd679343ae88764efef55ea12b2",
    "sugerDady": "0xA1A343B4245e4364e6b9c4574F9F7A3C1D849Ad6",
    "compoundImportAddress": "0x3545a9AB6a57B1172690769175A3242a644f1574",
    "flashImportAddress": "0xF9fa648c46bb1e1f249ABA973397077CDc20fC78",
    "jarConnector": "0x061133BE90f97B6Eb7f73eD9Dc50eFB1DD96ED72",
    "jar": "0x18DB5F7711d57974d825f9ca45D21627353bEb72",
    "compClaimData": "0x8A4D0ad9005154fa4b7A9A9896d97fb88Ee7f9aa"
  }